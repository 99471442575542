


<template>
	<div id="Warehouse">
		
		<div class="Warehouse_nav">
			
			<div class="Warehouse_nav_item">
				<p :class="{'active' : KnaTake == false}" @click="ChangeKnaTake('0')">背包</p>
				<p :class="{'active' : KnaTake == true}" @click="ChangeKnaTake('1')">提货列表</p>
			</div>
			
			<div class="Warehouse_nav_item"  v-show="KnaTake == false">
				<p @click="SelectAll">全选</p>
				<p @click="PostCash">分解</p>
				<p @click="PostExtract">提货</p>
			</div>
			<div class="Warehouse_nav_item" v-show="KnaTake == true">
				<van-dropdown-menu active-color="#000">
					<van-dropdown-item v-model="TakeValue" :options="TakeOption" />
				</van-dropdown-menu>
			</div>
		</div>
		
		<div class="Warehouse_list">
			<!-- :style="{'background-image': `url(${require('../../assets/images/q2/openbox/' + item.lv +'.png')})`}" -->
			<div class="Warehouse_item" v-for="(item,index) in StorageList" :key="index" ref="Item" :style="{'background-image': `url(${require('../../assets/images/985/home/' + item.lv +'.png')})`}">
				<div class="Warehouse_item_data">
					<p>{{item.name}}</p>
					<!-- <span>{{item.dura_alias}}</span> -->
				</div>
				<div class="Warehouse_item_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}" v-show="item.status == '0'" @click="Select(item,index)">
					<img :src="item.cover" alt="">
				</div>
				
				<div class="Warehouse_item_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}" v-show="item.status != '0'">
					<img :src="item.cover" alt="">
				</div>
				
			
				<div class="Warehouse_item_top">
					<p><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</p>
				</div>
			</div>
		</div>
		<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small>
		</el-pagination>
		<div class="fixbot">
			<div class="fixtop">
				<div class="PageNum d-flex justify-content-every" style="color:white">
					<p>已选 <span style="color:yellow">{{num}}</span> 件</p>
					<p>总价值 <span style="color:yellow">{{bean.toFixed(2) == -0.00 ? '0.00' : bean.toFixed(2)}}</span> 件</p>
				</div>
			</div>
			<div class="bot">
				<span @click="PostCash">分解</span>
				<span  @click="PostExtract">提货</span>
			</div>
		</div>
		<!-- 赠送遮罩层 -->
		<van-overlay :show="GiveShow">
		  <div class="wrapper">
		    <div class="LogOut_box">
				<div class="close" @click="GiveShow = false"><img src="@/assets/images/public/close.png" alt=""></div>
				<div class="tit">赠送</div>
				<div class="Give_pic">
					<div class="Pic_back" :style="{'background-image': 'url('+GiveBack+')'}">
						<img :src="GiveImg" alt="">
					</div>
				</div>
				<van-field center clearable label="对方邀请码" v-model="Invitation_code">
					<template #button>
						<van-button size="small" type="info" @click="PostGive">确定赠送</van-button>
					</template>
				</van-field>
				<div></div>
			</div>
		  </div>
		</van-overlay>
		

	</div>
</template>

<script>
	import{
		Storage,
		Cash,
		Extract,
		Give,
		PersonalInfo
	} from '@/network/api.js'
	import {
		mapMutations
	} from 'vuex'
	export default {
		name: 'Warehouse',
		data() {
			return {
				TakeValue: 4, //提货下拉绑定
				TakeOption: [{
						text: '申请提货',
						value: 4
					},
					{
						text: '正在发货',
						value: 5
					},
					{
						text: '等待收货',
						value: 6
					},
					{
						text: '提货完成',
						value: 1
					}
				],//提货下拉列表
				StorageList:[],//背包列表
				total:0,//背包总数据
				per_page:0,//一页数量
				page:'1',//背包列表页码
				status:'0',//提货物品状态type
				// 0 => '待操作',   默认状态
				// 1 => '领取成功',  已领取到Steam账户
				// 4 => '申请提货', 申请提货中，待平台发货的状态。
				// 5 => '正在发货', 平台已购买等待ZBT发货
				// 6 => '等待收货' ZBT已发货待领取
				
				Select_All:false,//是否全选状态
				
				KnaTake:false,//背包/提货
				
				GiveShow:false,//赠送遮罩层
				GiveImg:null,//赠送物品图片
				GiveBack:null,//赠送物品背景
				GiveId:'',//赠送物品Id
				Invitation_code:'',//赠送所需邀请码
				num:0,
				bean:0,
			}
		},
		
		created() {
			this.GetStorage()
		},
		
		methods:{
			//背包列表
			GetStorage(){
				Storage(this.page,this.status).then((res) => {
					// console.log(res.data.data)
					this.StorageList = res.data.data.data
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
				})
			},
			
			//背包/提货列表切换
			ChangeKnaTake(v){
				// console.log(v)
				if(v == '0'){
					this.KnaTake = false
					this.status = '0'
				}else{
					this.KnaTake = true
					this.status = '4'
					this.TakeValue = 4
				}
				this.page = '1'
				this.GetStorage()
			},
			
			//背包列表分页
			handleCurrentChange(val) {
				// console.log(val)
				this.page = val
				this.GetStorage()
			},
			
			//单选
			Select(item,index){
				// console.log(item)
				if (item.Select) {
					this.bean -= (item.bean*1)
					this.num -= 1
					delete item.Select
					this.$refs.Item[index].style.border = "1px solid transparent";
				} else {
					this.bean += (item.bean*1)
					this.num += 1
					let key = "Select";
					let value = true
					item[key] = value
					this.$refs.Item[index].style.border = "1px solid #E9B10E"
				}
			},
			
			//全选
			SelectAll() {
				if (this.Select_All == false) {
					for (let i = 0; i < this.StorageList.length; i++) {
						let key = "Select";
						let value = true
						this.StorageList[i][key] = value
						this.$refs.Item[i].style.border = "1px solid #E9B10E"
						this.bean += (this.StorageList[i].bean*1)
					}
					this.num  = this.StorageList.length
					this.Select_All = true
				} else {
					for (let i = 0; i < this.StorageList.length; i++) {
						delete this.StorageList[i].Select
						this.$refs.Item[i].style.border = "1px solid transparent"
					}
					this.bean = 0
					this.num  = 0
					this.Select_All = false
				}
			},
			
			//兑换金币(分解)
			PostCash(){
				let arr = this.StorageList.filter((item) => {
					return item.Select == true
				})
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id
					})
				}
				Cash(JSON.stringify(data)).then((res) => {
					// console.log(res)
					this.RefreshData(res.data.message)
				})
			},
			
			//物品提取(提货)
			PostExtract(){
				let arr = this.StorageList.filter((item) => {
					return item.Select == true
				})
				
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id
					})
				}
				Extract(JSON.stringify(data)).then((res) => {
					this.RefreshData(res.data.message)
				})
			},
			
			// 打开赠送遮罩层
			PostGiveShow(item){
				// console.log(item)
				this.GiveImg = item.cover
				this.GiveId = item.id
				this.GiveBack = item.lv_bg_image
				this.GiveShow = true
			},
			
			// 赠送
			PostGive() {
				Give(this.GiveId, this.Invitation_code).then((res) => {
					this.RefreshData(res.data.message)
					this.GiveShow = false
				})
			},
			
			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},
						
			//刷新数据
			RefreshData(message){
				this.$notify({
					type: 'success',
					message: message
				})
				this.GetStorage()
				this.GetPersonalInfo()
				for (let i = 0; i < this.StorageList.length; i++) {
					delete this.StorageList[i].Select
					this.$refs.Item[i].style.border = "1px solid transparent"
				}
				this.Select_All = false
				
			},
			
			...mapMutations([
				'PostUser'
			])
		},
		
		watch:{
			TakeValue(v){
				// console.log(v)
				this.status = String(v)
				this.page = '1'
				this.GetStorage()
			}
		}
	}
</script>

<style lang="scss">
	#Warehouse {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		.PageNum{
			display: flex;
			justify-content: space-around;
			padding-top: 10px;
			font-size: 14px;
		}
		.fixbot{
			position: fixed;
			bottom: 0;
			width: 390px;
			height: 120px;
			background: rgb(9, 14, 35);
			.fixtop{
				height: 50px;
			}
			.bot{
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				span{
					width: 100px;
					height: 50px;
					text-align: center;
					line-height: 50px;
				}
				span:nth-child(1){
					background: url('../../assets/images/q2/Backpack/lefts.png') no-repeat center;
					background-size: 100% 100%;
				}
				span:nth-child(2){
					background: url('../../assets/images/q2/Backpack/lefts.png') no-repeat center;
					background-size: 100% 100%;
				}
			}
		}
		.Warehouse_nav {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.Warehouse_nav_item {
				display: flex;

				p {
					height: 30px;
					padding: 0 10px;
					// background: rgba($color: #fff, $alpha: .2);
					background: #747474;
					// border: 1px solid #fff;
					color: #fff;
					font-size: 12px;
					line-height: 30px;
					text-align: center;
					margin-right: 5px;
					border-radius: 4px;
					box-sizing: border-box;
					background: url('../../assets/images/q2/Backpack/lefts.png') no-repeat center;
					background-size: 100% 100%;
					&.active {
						// background: #ff8222;
						// color: #fff;
						// border: 1px solid #ff8222;
						background: url('../../assets/images/q2/Backpack/right.png') no-repeat center;
						background-size: 100% 100%;
					}
				}
				.van-dropdown-menu__bar{
					height: 30px !important;
					width: 100px;
					background: #ffd322;
					border-radius: 4px;
					.van-ellipsis{
						font-size: 12px !important;
						color: #000;
					}					
				}
				.van-overlay{
					background: none;
				}
				.van-dropdown-item__content{
						width: 100px !important;
						left: calc(100% - 110px);
						border-radius: 0 0 4px 4px;
						.van-cell{
							font-size: 12px;
							padding: 5px;
							.van-cell__title{
								width: 100%;
								text-align: center;
							}
							.van-cell__value{
								flex: none;
							}
						}
						.van-icon-success::before{
							display: none;
						}
				}
			}
		}
		
		.Warehouse_list {
			width: 100%;
			min-height: 340px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 10px;
		
			align-content: flex-start;
			.Warehouse_item {
				width: 49%;
				// background: rgba($color: #000, $alpha: .4);
				padding: 5px 10px 12px;
				border: 1px solid transparent;
				box-sizing: border-box;
				border-radius: 4px;
				margin-top: 14px;
				// background: url('../../assets/images/q2/Backpack/list.png');
				background-size: 100% 100%;
				align-content: flex-start;
				.Warehouse_item_top {
					width: 100%;
					display: flex;
					height: 30px;
					p {
						margin: 0 auto;
						width: 50%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #f1bc60;
						// width:200px;
					

						img {
							width: 18px;
						}
					}
					.van-button--small{
						height: 26px;
						margin-left: 26px;
						.van-button__text{
							width: 100% !important;
						}
					}
					
				}
		
				.Warehouse_item_pic {
					width: 80%;
					height: 80px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					background-size: 70% !important;
					background-position: center !important;
					background-repeat: no-repeat !important;
		
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
		
				.Warehouse_item_data {
					width: 100%;
					color: #fff;
					text-align: center;
					padding-top: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					p {
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 3px;
						width: 80%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
		
					span {
						font-size: 10px;
						color: #c6c6c6;
					}
				}
			}
		
		}
		.Give_pic{
			width: 200px;
			height: 200px;
			background: rgba($color: #000000, $alpha: .2);
			margin: 0 auto;
			border-radius: 14px;
			.Pic_back{
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-position: center;
				background-repeat: no-repeat;
				background-size: 80%;
				img{
					max-width: 90%;
					max-width: 90%;
				}
			}
			
		}
		.van-field__label{
			width: 70px;
		}
	}
</style>
